import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const baseURL = '/api/movementItas'

const service = createCrudService(baseURL, api)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.originCenter.options = ctx.originCenterOptions
  fieldsById.destinationCenter.options = ctx.destinationCenterOptions
  fieldsById.destinationCustomer.options = ctx.destinationCustomerOptions
  fieldsById.netWeightUnity.options = ctx.netWeightUnityOptions
}

service.calculateAvailableCapacities = async () => {
  try {
    var response = await api.post(`${baseURL}/calculateAvailableCapacities`)
    return response
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

export default service

<template>
  <div v-if="metadata != null">
    <div
      v-if="!isMasterData"
      class="container container--fluid movement-container"
    >
      <bc-btn
        class="v-btn v-btn--has-bg theme--dark elevation-0 v-size--default primary"
        text
        @click="calculateAvailableCapacities()"
        >{{
          $t(
            'application.pages.movementItas.calculateAvailableCapacityButton.label'
          )
        }}</bc-btn
      >
    </div>

    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView, useScenarioOpen } from '@cargill/shared'
import service from '../api/movementItasService'

const { isScenarioMaster } = useScenarioOpen()
export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  methods: {
    async getMeta() {
      const meta = await service.getMetaWithValidation()
      return meta
    },
    async calculateAvailableCapacities() {
      try {
        await service.calculateAvailableCapacities()
        this.notify.success({
          title: this.$t(
            'application.pages.movementItas.events.availableCapacitiesGeneratedSuccessfully'
          )
        })
      } catch (e) {
        this.notify.error({
          title: this.$t(
            'application.pages.movementItas.events.errorOnGenerateAvailableCapacities'
          )
        })
      }
    }
  },
  computed: {
    isMasterData() {
      return isScenarioMaster()
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>

<style scoped lang="scss">
.movement-container {
  display: flex;
  justify-content: flex-end;
}
</style>
